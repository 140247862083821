import { memo, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ArrowForwardIos } from '@material-ui/icons'
import { Typography, withStyles } from '@material-ui/core'

import { TextWithLinks } from '@tabeeb/modules/shared/textWithLinks'

import styles from './styles'

const InfoLine = ({ classes, icon: InfoIcon, value, link, title, badge, onClick, buttonEl }) => {
  const valueElement = Array.isArray(value)
    ? value?.map((value, index) => (
        <Fragment key={index}>
          {index > 0 && <ArrowForwardIos className={classes.separator} />}
          {value}
        </Fragment>
      ))
    : value

  const valueTitle = Array.isArray(value) ? value?.join(', ') : value

  return (
    <div
      className={classNames(classes.container, { [classes.active]: Boolean(onClick) })}
      title={title}
      onClick={onClick}
    >
      {InfoIcon && <InfoIcon className={classes.icon} />}
      <Typography className={classes.text} title={valueTitle}>
        {link ? <TextWithLinks>{valueElement}</TextWithLinks> : valueElement}
      </Typography>
      {badge}
      {buttonEl}
    </div>
  )
}

InfoLine.defaultProps = {
  link: false,
}

InfoLine.propTypes = {
  classes: PropTypes.shape({
    active: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    separator: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.element]).isRequired,
  badge: PropTypes.element,
  buttonEl: PropTypes.element,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  link: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default memo(withStyles(styles)(InfoLine))
